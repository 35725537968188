<template>
    <Autocomplete
        v-bind="childProps"
        :id="idAttr"
        :items="items"
        v-model="fieldValue"
        :name="name"
        @update:model-value="handleModelValueChange"
        @search="$emit('search', $event)"
        :is-invalid="!!errorMessage"
        :error-message="errorMessage"
        :disabled="disabled"
        :mandatory="mandatory"
        :underlined="underlined"
        :readonly="readonly"
        :no-data-text="noDataText"
        :form-floating="formFloating">
        <template #label-end><slot name="label-end"></slot></template>
        <template #field-selection="scope"><slot name="field-selection" v-bind="scope"></slot></template>    
        <template #list-item="scope"><slot name="list-item" v-bind="scope"></slot></template>    
    </Autocomplete>
</template>

<script lang="ts" setup>
import { useField } from "vee-validate";
import Autocomplete from "./autocomplete.vue";
import commonProps from "./props";
import omit from 'lodash/omit';
import { computed, toRef } from "vue";
import kebabCase from "lodash/kebabCase";

const props = defineProps({
    ...commonProps,
    name: {
        type: String,
        required: true
    },
    formFloating: Boolean
})

const emit = defineEmits<{
    (e: 'change', value: any): void,
    (e: 'search', value: string): void
}>()

const idAttr = computed(() => props.id ? props.id : 'autocomplete-' + kebabCase(props.name));

const childProps = omit(props, ['name', 'value', 'items']);

const {
    value: fieldValue,
    handleChange,
    errorMessage
} = useField(toRef(props, 'name'));

const handleModelValueChange = (item: unknown) => {
    emit('change', item)
    handleChange(item)
}
</script>